import img1 from './static/1.jpg';
import img2 from './static/2.jpg';
import img3 from './static/3.jpg';
import img4 from './static/4.jpg';
import img5 from './static/5.jpg';
import img6 from './static/6.jpg';
import img7 from './static/7.jpg';
import img7_1 from './static/7-1.gif';
import img7_2 from './static/7-2.gif';
import img7_3 from './static/7-3.gif';
import img8 from './static/8.jpg';
import img8_1 from './static/8-1.gif';
import img8_2 from './static/8-2.gif';
import img9 from './static/9.jpg';
import img9_1 from './static/9-1.gif';
import img10 from './static/10.jpg';
import img11 from './static/11.jpg';
import img12 from './static/12.jpg';
import img13 from './static/13.jpg';
import img14 from './static/14.jpg';
import img15 from './static/15.jpg';
import img16 from './static/16.jpg';
import img17 from './static/17.jpg';
import img17_1 from './static/17-1.jpg';
import img17_1_1 from './static/17-1-1.gif';
import img17_2 from './static/17-2.png';
import img18 from './static/18.jpg';
import img19 from './static/19.jpg';
import img20 from './static/20.gif';
import img21 from './static/21.gif';
import img22 from './static/22.png';
import img23 from './static/23.jpg';
import img24 from './static/24.jpg';
import img25 from './static/25.jpg';
import img26 from './static/26.jpg';
import img27 from './static/27.jpg';
import img28 from './static/28.jpg';
import img29 from './static/29.gif';
import img30 from './static/30.gif';


import img31 from './static/31.jpg';
import img32 from './static/32.jpg';
import img33 from './static/33.jpg';
import img34 from './static/34.jpg';
import img35 from './static/35.jpg';
import img36 from './static/36.jpg';
import img37 from './static/37.jpg';




import imgEnd from './static/end.gif';
import SVGA from 'svgaplayerweb'
import './App.css';
import { useEffect } from 'react';
import canvas2 from './static/520.svga';
import canvas4 from './static/666.svga';
import canvas6 from './static/love.svga';
import canvas3 from './static/bixin.svga';
import canvas5 from './static/memeda.svga';
import canvas1 from './static/gaobaiqiqiu.svga';
function App() {
  const imgList =[
    {
      src:[img31],
      style:{
        width:'100%',
      }
    },
    {
      src:[img32],
      style:{
        width:'100%',
      }
    },
    {
      src:[img2],
      style:{
        width:'100%',
      }
    },
    {
      src:[img3],
      style:{
        width:'100%',
      }
    },
    {
      src:[img4],
      style:{
        width:'100%',
      }
    },
    {
      src:[img5],
      style:{
        width:'100%',
      }
    },
    {
      src:[img6],
      style:{
        width:'100%',
      }
    },
    {
      src:[img7],
      style:{
        width:'100%',
      }
    },
    {
      src:[img7_1,img7_2,img7_3],
      style:{
        width:'25%',
        margin:'60px 3%'
      }
    },
    {
      src:[img8],
      style:{
        width:'100%',
      }
    },
    {
      src:[img8_1,img8_2],
      style:{
        width:'25%',
        margin:'60px 10%'
      }
    },
    {
      src:[img9],
      style:{
        width:'100%',
      }
    },
    {
      src:[img9_1],
      style:{
        width:'30%',
        margin:'60px 10%'
      }
    },
    {
      src:[img10],
      style:{
        width:'100%',
      }
    },
  ]
    
    
    const imgList2 =[
    {
      src:[img11],
      style:{
        width:'100%',
      }
    },{
      src:[img12],
      style:{
        width:'100%',
      }
    },{
      src:[img13],
      style:{
        width:'100%',
      }
    },{
      src:[img14],
      style:{
        width:'100%',
      }
    },{
      src:[img29,img30],
      style:{
        width:'25%',
        margin:'60px 10%'
      }
    },{
      src:[img15],
      style:{
        width:'100%',
      }
    },{
      src:[img16],
      style:{
        width:'100%',
      }
    },{
      src:[img17],
      style:{
        width:'100%',
      }
    },
    {
      src:[img17_1],
      style:{
        width:'100%',
      }
    },
    {
      src:[img17_1_1],
      style:{
        width: '30%',
        margin:'60px 10%'
      }
    },
    {
      src:[img17_2],
      style:{
        width:'100%',
      }
    },
    {
      src:[img18],
      style:{
        width:'100%',
      }
    },{
      src:[img19],
      style:{
        width:'100%',
      }
    },{
      src:[img20],
      style:{
        width:'100%',
      }
    },{
      src:[img21],
      style:{
        width:'100%',
      }
    },{
      src:[img22],
      style:{
        width:'100%',
      }
    },{
      src:[img23],
      style:{
        width:'100%',
      }
    },{
      src:[img24],
      style:{
        width:'100%',
      }
    },{
      src:[img25],
      style:{
        width:'100%',
      }
    },{
      src:[img26],
      style:{
        width:'100%',
      }
    },{
      src:[img27],
      style:{
        width:'100%',
      }
    },{
      src:[img28],
      style:{
        width:'100%',
      }
    },
    {
      src:[img33],
      style:{
        width:'100%',
      }
    },{
      src:[img34],
      style:{
        width:'100%',
      }
    },{
      src:[img35],
      style:{
        width:'100%',
      }
    },
    {
      src:[img36],
      style:{
        width:'100%',
      }
    },{
      src:[img37],
      style:{
        width:'100%',
      }
    },
  ]

  const canvasList=[
    {
      src:[canvas1],
      id:['canvas1'],
      style:{
        width:'70vw',
        height:'70vw'
      }
    },
    {
      src:[canvas2],
      id:['canvas2'],
      style:{
        width:'70vw',
        height:'70vw',
        margin: '0 0 40px',
      }
    },
    {
      src:[canvas3,canvas4],
      id:['canvas3','canvas4'],
      style:{
        width:'33vw',
        height:'33vw',
        margin: '0 5vw 40px',
      }
    },
    {
      src:[canvas5,canvas6],
      id:['canvas5','canvas6'],
      style:{
        width:'33vw',
        height:'33vw',
        margin: '0 5vw 100px',
      }
    }
  ]

  useEffect(()=>{
    for(let i=1;i<=6;i++){
      new SVGA.autoload(document.querySelector('#canvas'+i))
    }
  })
  


  return (
    <div className="App">
      {
        (imgList || []).map(item => (
          <div className="imgBox">
            {
              (item.src || []).map(t=>(
                <img style={item.style} src={t}/>
              ))
            }
            
          </div>
        ))
      }
      <div style={{background:'#181921',marginBottom:'60px',}}>
      {
        (canvasList||[]).map(item=>(
          <div className="imgBox" style={{background:'none',padding:'0 10%',justifyContent:'space-around',margin:0,}}>
            {
              (item.src || []).map((t,i)=>(
                <div src={t} loops="0" id={item.id[i]} clearsAfterStop="true" style={item.style}></div>
              ))
            }
          </div>
        ))
      }
      </div>

      {
        (imgList2 || []).map(item => (
          <div className="imgBox">
            {
              (item.src || []).map(t=>(
                <img style={item.style} src={t}/>
              ))
            }
            
          </div>
        ))
      }
      
      <div className="imgBox">
        <img style={{width:'100%'}} src={imgEnd}/>
      </div>
    </div>
  );
}

export default App;
